import React from 'react';
import BRAND_CONFIG from '../BRAND_CONFIG';
import AuthForm from '../components/AuthForm';

const {
    title, ctaText, ctaLoadingText,
} = BRAND_CONFIG.pages.register;

const RegisterPage = () => (
    <AuthForm
        title={title}
        ctaText={ctaText}
        ctaLoadingText={ctaLoadingText}
        submitType="register"
    />
);

export default RegisterPage;
